<template>
    <div class="view pa24 rentalManagementTable">
        <div class="d-flex">
            <div>
                <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
                <!-- <el-button type="primary">导出数据</el-button> -->
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="expand">
                    <template slot-scope="props" v-if="props.row.houseManageResponseList">
                        <div class="myCardView">
                            <div class="myCardItem" v-for="(row, index) in props.row.houseManageResponseList"
                                :key="index">
                                <el-card :body-style="{ padding: '10px' }" shadow="always">
                                    <div class="d-flex">
                                        <div class="leftIcon flex-c-c">
                                            <div class="el-icon-school"></div>
                                        </div>
                                        <div class="rightText">
                                            <div>{{ row.buildName }}</div>
                                            <div>{{ row.number }}单元/{{ row.houseName }}</div>
                                            <div class="time mt3" style="color:#F56C6C"
                                                v-if="row.endTime && (time > row.endMS)">已到期</div>
                                            <div class="time mt3" v-if="row.endTime && (time < row.endMS)">{{
                                                    row.endTime
                                            }} 到期</div>
                                            <div class="time mt3" v-if="!row.endTime">无到期时间</div>
                                        </div>
                                        <div class="renew" @click="showRenewModelFun(row)">续 期</div>
                                        <!-- <div class="secede" @click="secedePrakFun(scope.row.parkId)">踢出房屋</div> -->
                                    </div>
                                </el-card>
                            </div>
                        </div>

                    </template>
                </el-table-column>
                <el-table-column prop="companyName" align="center" label="企业名称" show-overflow-tooltip />
                <el-table-column prop="companyBusiness" align="center" label="经营范围" show-overflow-tooltip />
                <el-table-column prop="companyLegal" align="center" label="联系人" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="phone" align="center" label="联系电话" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" label="操作" width="130">
                    <template slot-scope="scope">
                        <el-button class="mr10" type="text" style="color:#F56C6C" @click="leavePark(scope.row)">移出园区
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="renewModelTitle" :visible.sync="showRenewModel" width="300px" :append-to-body="true">
            <div class="mr20 ml20">
                <el-date-picker v-model="renewMonth" type="datetime" placeholder="选择到期时间" align="right"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showRenewModel = false">取 消</el-button>
                <el-button type="primary" @click="renewFun" :loading="loadingBtn">续 期
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getParkCompanyLink, delParkCompanyLink, setHouseManageCompanyEndTime } from "@/api/parkManagement";
export default {
    name: "houseTable",
    components: {
        commonTable
    },
    data() {
        return {
            pickerOptions: {
                shortcuts: [{
                    text: '一月',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() + 3600 * 1000 * 24 * 30);
                        picker.$emit("pick", date)
                    }
                }, {
                    text: '半年',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() + 3600 * 1000 * 24 * 30 * 6);
                        picker.$emit('pick', date);
                    }
                }, {
                    text: '一年',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() + 3600 * 1000 * 24 * 30 * 12);
                        picker.$emit('pick', date);
                    }
                }]
            },
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            searchData: { name: "" },
            time: new Date().getTime(),
            showRenewModel: false,
            renewItemData: {},
            renewMonth: "",
            loadingBtn: false,
            renewModelTitle: ""
        };
    },
    computed: {
        watchCheckedPark() {
            return this.$store.state.checkedPark
        }
    },
    watch: {
        watchCheckedPark(row) {
            this.parkId = row.parkId;
            this.currentPage = 1
            this.getList();
        }
    },
    async created() {
        let checkedPark = sessionStorage.getItem('checkedPark');
        this.parkId = JSON.parse(checkedPark).parkId;
        this.getList();
    },
    methods: {
        /**@method 显示续费表单 */
        showRenewModelFun(row) {
            this.renewItemData = row;
            this.showRenewModel = true;
            this.renewModelTitle = row.buildName + "/" + row.number + "单元" + row.houseName;
        },
        /**@method 续费 */
        renewFun() {
            let params = {
                endTime: this.renewMonth,
                familyId: this.renewItemData.familyId,
                houseId: this.renewItemData.houseId,
            };
            this.loadingBtn = true;
            setHouseManageCompanyEndTime(params).then(res => {
                this.loadingBtn = false;
                if (res.code === 200) {
                    this.$message({
                        message: "续费完成",
                        type: "success"
                    })
                    this.showRenewModel = false;
                    this.getList();
                } else {
                    this.$message({
                        message: res.message,
                        type: "success"
                    })
                }
            }).catch(err => {
                this.$message({
                    message: err.message,
                    type: "success"
                })
            })
        },
        /**@method 搜索 */
        searchFun() {
            this.currentPage=1;
            this.getList();
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                parkId: this.parkId,
                hireTitle: this.searchData.name,
            };
            this.loading = true;
            let res = await getParkCompanyLink(params);
            try {
                this.loading = false;

                const { data } = res;
                let tableData = [];
                for (let row of data.pageInfo.list) {
                    if (row.houseManageResponseList) {
                        let houseManageResponseList = [];
                        for (let child of row.houseManageResponseList) {
                            child.endMS = new Date(child.endTime)
                            houseManageResponseList.push(child);
                        }
                        row.houseManageResponseList = houseManageResponseList;
                    }
                    tableData.push(row);
                }
                this.tableData = tableData;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        /**@method 退出园区
         * @param {Object} val - 当前点击行的值
         */
        leavePark(row) {
            this.$confirm('此操作将把公司移出园区, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delParkCompanyLink({
                    companyId: row.companyId,
                    parkId: this.parkId
                }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '踢出完成'
                    });
                    this.getList();
                }).catch(err => {
                    this.$message({
                        type: "error",
                        message: err.message
                    })
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
    },
};
</script>

<style>
.rentalManagementTable .el-tag {
    height: 32px;
    padding: 0 10px;
    line-height: 30px;
    margin-left: 10px;
}

.rentalManagementTable .input-new-tag {
    width: 90px;
    vertical-align: bottom;
}
</style>
<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}

.myCardView {
    display: flex;
    flex-wrap: wrap;
}

.myCardItem {
    width: 240px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.leftIcon {
    background: #51CBCD;
    font-size: 33px;
    width: 60px;
    height: 60px;
    color: #fff;
    border-radius: 50%;
}

.rightText {
    font-size: 15px;
    margin-left: 10px;

    .time {
        color: #999;
        font-size: 12px;
    }
}

.d-flex {
    position: relative;
}

.renew {
    color: #51CBCD;
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
    font-size: 13px;
}

.secede {
    color: #F56C6C;
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
    font-size: 13px;
}
</style>